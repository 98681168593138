import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles.js";

import ShapeMobileImg from "@images/shapes/topbar-shape-mobile.png";
import ShapeImg from "@images/shapes/topbar-shape.png";
import LightImg from "@images/shapes/topbar-light.png";

const TopBar = ({ active }) => {
  return (
    <Wrapper active={active}>
      <img src={LightImg} />
      <div>
        <img src={ShapeMobileImg} />
        <img src={ShapeImg} />
      </div>
    </Wrapper>
  );
};

TopBar.propTypes = {
  active: PropTypes.bool,
};

TopBar.defaultProps = {
  active: false,
};

export default TopBar;
