import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";

import "@theme/sanitize.css";
import "@theme/fonts.css";
import { mainTheme } from "@theme/theme";

import TopBar from "@molecules/TopBar/index.js";

import { GlobalStyle } from "./styles.js";

const Outter = ({ children }) => {
  const [pageActive, setPageActive] = useState(false);

  useEffect(() => {
    setTimeout(() => setPageActive(true), 1000);
  });

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <TopBar active={pageActive} />
      {children}
    </ThemeProvider>
  );
};

export default Outter;
