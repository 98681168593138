import styled from "styled-components";
import BgImg from "@images/hero/hero-bg.png";

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  max-width: 2400px;
  opacity: ${({ active }) => (active ? "1" : "0")};
  transition: opacity 0.4s ease;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: center center url(${BgImg}) no-repeat;
    background-size: cover;
    content: "";
  }

  > img {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translate3d(-50%, 0, 0);
    opacity: ${({ active }) => (active ? "1" : "0")};
    transition: opacity 1s ease;
    will-change: opacity;
  }

  > div {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: ${({ active }) => (active ? "100%" : "0")};
    height: 100%;
    transition: width 0.4s 0.6s ease;
    will-change: width;

    > img {
      position: absolute;
      top: 0;
      right: 0;
      width: 100wv;
      height: auto;
    }

    > img:nth-child(1) {
    }

    > img:nth-child(2) {
      display: none;
    }
  }

  ${({ theme }) => theme.media.s} {
    > img {
      width: 160%;
    }

    > div {
      > img {
        width: 60vw;
      }
    }
  }

  ${({ theme }) => theme.media.m} {
    height: 90px;

    > img {
      width: 140%;
    }
  }

  ${({ theme }) => theme.media.l} {
    height: 100px;

    > img {
      width: 130%;
    }

    > div {
      > img:nth-child(1) {
        display: none;
      }

      > img:nth-child(2) {
        display: block;
        right: 0;
        width: 100vw;
      }
    }
  }

  ${({ theme }) => theme.media.xl} {
    height: 140px;

    > img:nth-child(2) {
      width: 120%;
    }
  }

  ${({ theme }) => theme.media.xxl} {
    height: 160px;

    > img:nth-child(2) {
      width: 110%;
    }
  }
`;
